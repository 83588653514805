const appConfig = {
  servicePrefix: {
    local: {
      auth: "http://localhost:40026",
      ecommerce: "http://localhost:40027",
    },
    production: {
      auth: "https://api.kamusalmunawwir.id/auth",
      ecommerce: "https://api.kamusalmunawwir.id/ecommerce",
    },
  },
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
  stage: "production",
};

export default appConfig;
